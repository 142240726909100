import {
    Box,
    Container,
    Flex,
    Grid,
    HStack,
    Icon,
    Stack,
    Text,
    VStack,
    VisuallyHidden,
    chakra,
    useColorModeValue,
} from '@chakra-ui/react'
import { FC, ReactNode, RefObject } from 'react'
import { IconType } from 'react-icons'
import {
    FaCogs,
    FaEnvelope,
    FaFacebook,
    FaHeart,
    FaInstagram,
    FaMapMarkerAlt,
    FaShieldAlt,
    FaSuitcase,
    FaTiktok,
    FaUser,
} from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { COMMON_NAVLINKS } from '../../constants'

const Logo = (props: any) => {
    return <img src={logo} style={styles.photo} alt="Elementary Creative" />
}

interface FooterProps {
    servicesRef: RefObject<HTMLDivElement>
    privacyRef: RefObject<HTMLDivElement>
    portfolioRef: RefObject<HTMLDivElement>
}

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            target="_blank"
            rel="noreferrer"
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'700'} fontSize={'xl'} mb={2}>
            {children}
        </Text>
    )
}

interface NavLinkItemProps {
    to: string
    label: string
    icon?: IconType
    onClick?: () => void
}

const NavLinkItem: React.FC<NavLinkItemProps> = ({
    to,
    label,
    icon,
    onClick,
}) => {
    return (
        <Flex
            as={Link}
            alignItems="center"
            gap={2}
            to={to}
            onClick={onClick}
            textAlign={'left'}
            fontSize={'lg'}
        >
            {icon && <Icon as={icon} size="sm" />}
            {label}
        </Flex>
    )
}

const Footer: FC<FooterProps> = ({ servicesRef, privacyRef, portfolioRef }) => {
    const navigate = useNavigate()
    const scrollToServices = () => {
        servicesRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollToPrivacyPolicy = () => {
        privacyRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollToPortfolio = () => {
        portfolioRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <Box
            width="full"
            pt={{ base: '2%', md: '4%', lg: '2%' }}
            bg={'gray.300'}
            color={useColorModeValue('gray.700', 'gray.200')}
            justifyItems={'center'}
        >
            <Container
                display={'flex'}
                maxW={'full'}
                w={'full'}
                py={'4%'}
                borderTop={{ base: '0', md: '2px' }}
                borderColor={{ base: 'none', md: 'gray.300' }}
                justifyContent={'center'}
            >
                <Grid
                    templateColumns={{
                        base: 'repeat(2, 1fr)', // 2 columns on small screens
                        md: 'repeat(4, 1fr)',
                    }}
                    gap={6}
                    maxW={'6xl'}
                    w={{ base: 'full', md: '90%', lg: '80%', xl: '70%' }}
                    alignItems={'start'} // Align to the top
                    justifyContent={'center'} // Adjust spacing between items
                    rowGap={8}
                    fontWeight={'400'}
                >
                    {/* Company Section */}
                    <Stack
                        align={{ base: 'center', md: 'start' }}
                        sx={styles.links}
                        fontSize={'2xl'}
                    >
                        <ListHeader>COMPANY</ListHeader>
                        <NavLinkItem
                            to={COMMON_NAVLINKS.contact.link}
                            label={COMMON_NAVLINKS.contact.label}
                            icon={FaEnvelope}
                        />
                        <NavLinkItem
                            to={'/portfolio'}
                            label="Portfolio"
                            icon={FaSuitcase}
                            onClick={() => {
                                navigate('/portfolio')
                                setTimeout(scrollToPortfolio, 0)
                            }}
                        />
                        <NavLinkItem
                            to={COMMON_NAVLINKS.testimonials.link}
                            label={COMMON_NAVLINKS.testimonials.label}
                            icon={FaUser}
                        />
                    </Stack>

                    {/* Info Section */}
                    <Stack
                        align={{ base: 'center', md: 'start' }}
                        sx={styles.links}
                    >
                        <ListHeader>INFO</ListHeader>
                        <NavLinkItem
                            to={COMMON_NAVLINKS.privacyPolicy.link}
                            label={COMMON_NAVLINKS.privacyPolicy.label}
                            icon={FaShieldAlt}
                            onClick={() => {
                                navigate(COMMON_NAVLINKS.privacyPolicy.link)
                                setTimeout(scrollToPrivacyPolicy, 0)
                            }}
                        />
                        <NavLinkItem
                            to={COMMON_NAVLINKS.services.link}
                            label={COMMON_NAVLINKS.services.label}
                            icon={FaCogs}
                            onClick={() => {
                                navigate(COMMON_NAVLINKS.services.link)
                                setTimeout(scrollToServices, 0)
                            }}
                        />
                    </Stack>

                    {/* Address Section */}
                    <Stack
                        align={{ base: 'center', md: 'start' }}
                        sx={styles.links}
                    >
                        <ListHeader>ADDRESS</ListHeader>
                        <Text
                            align={{ base: 'center', md: 'start' }}
                            fontSize={'lg'}
                        >
                            <Icon as={FaMapMarkerAlt} size="sm" /> Vancouver,
                            BC, Canada
                        </Text>
                    </Stack>

                    {/* Social Media Section */}
                    <Stack align={{ base: 'center', lg: 'end' }} spacing={6}>
                        <Link to={'/'}>
                            <Logo
                                color={useColorModeValue('gray.700', 'white')}
                            />
                        </Link>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton
                                label={'Facebook'}
                                href={
                                    'https://www.facebook.com/elementarycreativeofficial/'
                                }
                            >
                                <FaFacebook color="#4267B2" />
                            </SocialButton>
                            <SocialButton
                                label={'Instagram'}
                                href={
                                    'https://www.instagram.com/elementarycreative/'
                                }
                            >
                                <FaInstagram color="#E1306C" />
                            </SocialButton>
                            <SocialButton
                                label={'TikTok'}
                                href={'https://tiktok.com/@elementarycreative'}
                            >
                                <FaTiktok color="#010101" />
                            </SocialButton>
                        </Stack>
                    </Stack>
                </Grid>
            </Container>

            <HStack
                justify={'center'}
                fontSize={'sm'}
                columnGap={'2xl'}
                wrap={'wrap'}
                py={{ base: '10%', md: '4%' }}
            >
                <Grid
                    templateColumns={{
                        base: '1fr', // Single column on mobile
                        md: 'repeat(3, 1fr)',
                    }}
                    justifyContent={'center'}
                    gap={6}
                >
                    {/* Vancouver & Immediate Surrounding Areas */}
                    <VStack align={'center'}>
                        <Text sx={styles.hyphenate}>
                            Vancouver Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            North Vancouver Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            West Vancouver Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Burnaby Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Richmond Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Coquitlam Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            New Westminster Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Delta Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Port Coquitlam Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Port Moody Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Maple Ridge Website Development
                        </Text>
                    </VStack>

                    {/* Fraser Valley & Suburbs */}
                    <VStack align={'center'}>
                        <Text sx={styles.hyphenate}>
                            Langley Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Surrey Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Pitt Meadows Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            White Rock Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Abbotsford Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Chilliwack Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Mission Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Hope Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Harrison Hot Springs Website Development
                        </Text>
                    </VStack>

                    {/* Northern and More Remote Areas */}
                    <VStack align={'center'}>
                        <Text sx={styles.hyphenate}>
                            Bowen Island Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Anmore Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Belcarra Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Fraser Valley Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Agassiz Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Clayburn Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Yarrow Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Rosedale Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Deroche Website Development
                        </Text>
                        <Text sx={styles.hyphenate}>
                            Seabird Island Website Development
                        </Text>
                    </VStack>
                </Grid>
            </HStack>

            <VStack
                align={'center'}
                py={{ base: '10%', md: '3%' }}
                w={'full'}
                bg={'black'}
                columnGap={'3xl'}
                justify={'space-around'}
                color={'white'}
                fontSize={'md'}
            >
                <Text>© 2024 Elementary Creative. All rights reserved</Text>
                <Text>
                    Made with{' '}
                    {<Icon color={'pink.500'} as={FaHeart} size="sm" />} by{' '}
                    <a
                        href="https://www.elementarycreative.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Elementary Creative
                    </a>
                </Text>
            </VStack>
        </Box>
    )
}
const styles = {
    photo: {
        height: '50px',
    },
    links: {
        textAlign: 'left',
        fontSize: 16,
        '& a': {
            '&:hover': { color: 'pink.400' },
        },
    },
    hyphenate: {
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        hyphens: 'auto',
        marginBottom: 2,
        paddingLeft: 2,
        letterSpacing: 1,
    },
}

export default Footer
