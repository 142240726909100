import { Box, Stack, chakra, useColorModeValue } from '@chakra-ui/react'
import { InlineWidget } from 'react-calendly'

const GetStarted = () => {
    return (
        <Stack
            pb={{ base: 8, md: 16 }}
            mb={16}
            direction={'column'}
            spacing={5}
            alignItems={'center'}
            justifyContent="space-evenly"
            rounded="lg"
            boxShadow="md"
            bg={useColorModeValue('gray.100', 'gray.700')}
            p={{ base: 8, md: 16 }}
        >
            <Box>
                <chakra.h1 fontSize="4xl" lineHeight={1.2} fontWeight="bold">
                    Ready to get started?
                </chakra.h1>
                <chakra.h2
                    fontSize="2xl"
                    lineHeight={1.2}
                    fontWeight="bold"
                    bgGradient="linear(to-l, #0ea5e9,#2563eb)"
                    bgClip="text"
                >
                    Get in touch today.
                </chakra.h2>
            </Box>

            <Box
                h={{ base: '1200px', md: '1300px', lg: '1300px', xl: '920px' }}
                py={20}
                overflow={'auto'}
                w={'full'}
            >
                <InlineWidget
                    styles={{ height: '100%' }}
                    url="https://calendly.com/elementarycreative/30min"
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                />
            </Box>
            {/* <Link to="/contact">
                    {' '}
                    <Button
                        color="white"
                        variant="solid"
                        size="lg"
                        rounded="md"
                        mb={{ base: 2, sm: 0 }}
                        lineHeight={1}
                        bgGradient="linear(to-l, #0ea5e9,#2563eb)"
                        _hover={{ bgGradient: 'linear(to-l, #0ea5e9,#2563eb)' }}
                    >
                        Get Started
                    </Button>
                </Link> */}
        </Stack>
    )
}

export default GetStarted
