import {
    Box,
    Container,
    Heading,
    Image,
    Skeleton,
    Stack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'

import { PackagingData } from '../../portfolio'

interface PackagingItemProps {
    name: string
    desc: string
    img: string
    link: string
}

const PackagingItem = ({ name, desc, img, link }: PackagingItemProps) => {
    return (
        <Box
            width={{ base: 'xs', sm: '2xs', md: 'sm' }}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            padding={1}
            boxShadow={
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
            }
        >
            <Image
                borderTopRadius={'lg'}
                fallback={<Skeleton w={'100%'} height={215.93} />}
                src={img}
                alt={name}
            />

            <Box p="6">
                <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                >
                    {name}
                </Box>
                <Box isTruncated as="h5" fontSize={16} fontWeight="light">
                    {desc}
                </Box>
            </Box>
        </Box>
    )
}

export default function Packaging() {
    return (
        <Container maxW={'10xl'} py={16} as={Stack} spacing={12} centerContent>
            <Heading size="xl">Packaging Design</Heading>
            <Wrap spacing="20px" align="center" justify="center">
                {PackagingData.map((p) => {
                    return (
                        <WrapItem>
                            <PackagingItem
                                name={p.name}
                                desc={p.desc}
                                img={p.img}
                                link={p.link}
                            />
                        </WrapItem>
                    )
                })}
            </Wrap>
        </Container>
    )
}
