import {
    Box,
    ChakraProvider,
    Grid,
    VStack,
    extendTheme,
} from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom'
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { useRef } from 'react'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar'
import ConnectPage from './Pages/ConnectPage/ConnectPage'
import ContactPage from './Pages/ContactPage/ContactPage'
import GraphicPage from './Pages/GraphicPage/GraphicPage'
import HomePage from './Pages/HomePage/HomePage'
import PortfolioPage from './Pages/PortfolioPage/PortfolioPage'
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage/PrivacyPolicyPage'
import ServicesPage from './Pages/ServicesPage/ServicesPage'

const theme = extendTheme({
    fonts: {
        heading: `'Open Sans', sans-serif`,
        body: `'Inter', sans-serif`,
    },
})

export const App = () => {
    const serviceRef = useRef<HTMLDivElement | null>(null)
    const privacyRef = useRef<HTMLDivElement | null>(null)
    const portfolioRef = useRef<HTMLDivElement | null>(null)

    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
                <Navbar />
                <Grid minH="calc(100vh - 299px)">
                    {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
                    <VStack spacing={8}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="/connect" element={<ConnectPage />} />
                            <Route
                                path="/portfolio"
                                element={
                                    <PortfolioPage
                                        portfolioRef={portfolioRef}
                                    />
                                }
                            />
                            <Route
                                path="/services"
                                element={
                                    <ServicesPage servicesRef={serviceRef} />
                                }
                            />
                            <Route
                                path="/services/graphic-design"
                                element={<GraphicPage />}
                            />
                            <Route
                                path="/privacy-policy"
                                element={
                                    <PrivacyPolicyPage
                                        privacyRef={privacyRef}
                                    />
                                }
                            />
                        </Routes>
                    </VStack>
                </Grid>
                <Footer
                    servicesRef={serviceRef}
                    privacyRef={privacyRef}
                    portfolioRef={portfolioRef}
                />
            </Box>
        </ChakraProvider>
    )
}
