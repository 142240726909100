import {
    Box,
    Button,
    Heading,
    Icon,
    Link,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react'
import {
    FaBriefcase,
    FaCalendarAlt,
    FaFacebook,
    FaGlobe,
    FaInstagram,
    FaTiktok,
} from 'react-icons/fa'

const links = [
    {
        name: 'Website',
        url: 'https://elementarycreative.com',
        icon: FaGlobe,
        color: 'gray.500',
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/elementarycreative',
        icon: FaInstagram,
        color: '#E1306C',
    },
    {
        name: 'Facebook',
        url: 'https://www.facebook.com/elementarycreativeofficial/',
        icon: FaFacebook,
        color: '#4267B2',
    },
    {
        name: 'TikTok',
        url: 'https://tiktok.com/@elementarycreative',
        icon: FaTiktok,
        color: '#010101',
    },
    {
        name: 'Portfolio',
        url: 'https://elementarycreative.com/portfolio',
        icon: FaBriefcase,
        color: 'teal.500',
    },
    {
        name: 'Free 30 Min Call',
        url: 'https://calendly.com/elementarycreative/30min',
        icon: FaCalendarAlt,
        color: 'blue.500',
    },
]

const ConnectPage = () => {
    const bgGradient = useColorModeValue(
        'linear(to-r, teal.400, blue.500)',
        'linear(to-r, teal.600, purple.800)'
    )
    const buttonColor = useColorModeValue('white', 'gray.800')
    const hoverColor = useColorModeValue('gray.100', 'gray.700')

    return (
        <Box
            w="full"
            minH="100vh"
            bgGradient={bgGradient}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <VStack spacing={6} w="full" maxW="500px" px={4}>
                {/* Header Section */}
                <Heading
                    fontSize="3xl"
                    color={buttonColor}
                    textAlign="center"
                    mb={4}
                    fontWeight="bold"
                >
                    Connect with Me
                </Heading>

                {/* Link Buttons */}
                {links.map((link) => (
                    <Link key={link.name} href={link.url} isExternal w="full">
                        <Button
                            w="full"
                            bg={buttonColor}
                            color="teal.500"
                            size="lg"
                            fontWeight="semibold"
                            borderRadius="full"
                            _hover={{ bg: hoverColor }}
                            boxShadow="lg"
                            transition="0.3s ease"
                            leftIcon={
                                <Icon
                                    as={link.icon}
                                    color={link.color}
                                    boxSize={7}
                                />
                            }
                        >
                            {link.name}
                        </Button>
                    </Link>
                ))}
            </VStack>
        </Box>
    )
}

export default ConnectPage
