import {
    Container,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    chakra,
} from '@chakra-ui/react'

import { FC, RefObject } from 'react'
import PricingCard from './pricingCard/PricingCard'
import { maintenancePlansList, plansList } from './pricingData'

interface ServiceProps {
    servicesRef: RefObject<HTMLDivElement>
}
const ServicesPage: FC<ServiceProps> = ({ servicesRef }) => {
    return (
        <Container
            ref={servicesRef}
            maxW="8xl"
            minH={'100vh'}
            mt={12}
            py="4"
            px={8}
            centerContent
        >
            <chakra.h2
                fontSize="2xl"
                fontWeight="bold"
                textAlign="center"
                mb={12}
            >
                Simple and affordable pricing
            </chakra.h2>
            <Tabs isLazy isFitted variant="enclosed" color="#33186B">
                <TabList>
                    <Tab>Website Development</Tab>
                    <Tab>Website Maintenance</Tab>
                    {/* <Tab>Graphic Design</Tab>
                    <Tab>Business Card Design</Tab>
                    <Tab>Logo Design</Tab> */}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex
                            wrap={'wrap'}
                            mt={4}
                            justifyContent={'center'}
                            columnGap={4}
                        >
                            {plansList.map((plan, index) => (
                                <PricingCard plan={{ ...plan, width: 'xs' }} />
                            ))}
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex
                            wrap={'wrap'}
                            mt={4}
                            justifyContent={'center'}
                            columnGap={4}
                        >
                            {maintenancePlansList.map((plan, index) => (
                                <PricingCard plan={{ ...plan, width: 'sm' }} />
                            ))}
                        </Flex>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    )
}

export default ServicesPage
