import { FC, RefObject } from 'react'
import Portfolio from '../../components/Portfolio/Portfolio'
interface PortfolioPageProps {
    portfolioRef: RefObject<HTMLDivElement>
}
const PortfolioPage: FC<PortfolioPageProps> = ({ portfolioRef }) => {
    return <Portfolio portfolioRef={portfolioRef} />
}

export default PortfolioPage
