import {
    Avatar,
    Button,
    Container,
    HStack,
    Heading,
    Icon,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'

import { Fragment, useEffect, useRef, useState } from 'react'

import user from '../../assets/user.jpg'

// Here we have used react-icons package for the icon
import { FaChevronLeft, FaChevronRight, FaStar } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'

interface TestimonialAttributes {
    name: string
    position: string
    company: string
    location: string
    content: string
}

const testimonials: TestimonialAttributes[] = [
    {
        name: 'Juan',
        position: 'Owner',
        company: 'Velezfit',
        location: 'London, ON',
        content: `I had the pleasure of working with one of the
        easiest to deal with designers I’ve ever met. He
        was very open and listened to all my ideas while
        at the same time providing his suggestions and
        expertise. I would certainly use
        elementarycreative for any future ventures and I
        highly recommend the use of their services to
        anyone!`,
    },
    {
        name: 'Jorge Velez',
        position: 'CEO',
        company: 'Ezee Computer Training',
        location: 'London, ON',
        content: `After Sahil redesigned our website we got lot of
        contracts. He not only has the skills to do the
        job but also he listens what you want to do and
        works hard to accomplish it. I highly recommend
        Elementary Creative to build or redesign your web
        site.`,
    },
    {
        name: 'Michael',
        position: 'Owner',
        company: 'Hybrid Roofing',
        location: 'Surrey, BC',
        content: `From start to finish, it was a well informed and consistently great experience with them. I am very happy with the final product. Would recommend their services to everyone!`,
    },
]

const Testimonials = () => {
    const [startIndex, setStartIndex] = useState(0)
    const showAtaTime =
        useBreakpointValue({
            base: 1,
            sm: 2,
            lg: 3,
        }) || 4
    const testimonialsRef = useRef<HTMLDivElement>(null)
    const { hash } = useLocation()
    const visibleTestimonials = testimonials.slice(
        startIndex,
        startIndex + showAtaTime
    )

    const buttonActiveColor = '#7360DF'
    const buttonInActiveColor = 'gray.200'

    useEffect(() => {
        if (hash === '#testimonials') {
            window.scrollTo({
                top: testimonialsRef.current?.offsetTop,
                left: 0,
                behavior: 'smooth',
            })
        }
    }, [hash])
    return (
        <Container
            ref={testimonialsRef}
            maxW="8xl"
            py={10}
            px={{ base: 0, md: 20 }}
            centerContent
        >
            <Stack spacing={0} mb={{ base: 8, md: 12 }} align={'center'}>
                <Heading>What Our Clients Say</Heading>
            </Stack>
            <HStack alignItems={'center'} gap={{ base: 2, md: 8 }}>
                <Button
                    color={'white'}
                    bg={
                        startIndex === 0
                            ? buttonInActiveColor
                            : buttonActiveColor
                    }
                    _hover={{
                        bg:
                            startIndex === 0
                                ? buttonInActiveColor
                                : buttonActiveColor,
                    }}
                    onClick={() =>
                        startIndex === 0 ? null : setStartIndex(startIndex - 1)
                    }
                >
                    <Icon as={FaChevronLeft} />
                </Button>

                {visibleTestimonials.map((obj, index) => (
                    <Fragment key={index}>
                        <Stack
                            direction={'column'}
                            spacing={4}
                            p={8}
                            borderRadius={'16px'}
                            boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
                            w={{ base: '4xs', xl: 'xs' }}
                            h={{ base: 'md', lg: 'sm' }}
                        >
                            <HStack>
                                <Avatar
                                    size="md"
                                    showBorder={true}
                                    borderColor="green.400"
                                    name="avatar"
                                    src={user}
                                    display={{
                                        base: 'none',
                                        sm: 'block',
                                    }}
                                />
                                <Stack justify="center" spacing={0}>
                                    <Avatar
                                        size="md"
                                        showBorder={true}
                                        borderColor="green.400"
                                        name="avatar"
                                        src={user}
                                        display={{
                                            base: 'block',
                                            sm: 'none',
                                        }}
                                    />
                                    <Text
                                        textAlign={'left'}
                                        noOfLines={1}
                                        fontWeight="bold"
                                        fontSize="md"
                                    >
                                        {obj.name}
                                    </Text>
                                    <Text
                                        textAlign={'left'}
                                        noOfLines={1}
                                        fontWeight="medium"
                                        fontSize="xs"
                                        color="gray.600"
                                    >
                                        {obj.position}, {obj.company}
                                    </Text>
                                    <Text
                                        noOfLines={1}
                                        textAlign={'left'}
                                        fontWeight="medium"
                                        fontSize="xs"
                                        color="gray.600"
                                    >
                                        {obj.location}
                                    </Text>
                                </Stack>
                            </HStack>

                            <Stack
                                direction="column"
                                spacing={4}
                                textAlign="left"
                                maxW="4xl"
                            >
                                <HStack>
                                    <Icon
                                        as={FaStar}
                                        w={4}
                                        h={4}
                                        color="gold"
                                    />
                                    <Icon
                                        as={FaStar}
                                        w={4}
                                        h={4}
                                        color="gold"
                                    />
                                    <Icon
                                        as={FaStar}
                                        w={4}
                                        h={4}
                                        color="gold"
                                    />
                                    <Icon
                                        as={FaStar}
                                        w={4}
                                        h={4}
                                        color="gold"
                                    />
                                    <Icon
                                        as={FaStar}
                                        w={4}
                                        h={4}
                                        color="gold"
                                    />
                                </HStack>
                                <Text
                                    noOfLines={9}
                                    fontSize="md"
                                    fontWeight="medium"
                                >
                                    {obj.content}
                                </Text>
                            </Stack>
                        </Stack>
                    </Fragment>
                ))}

                <Button
                    color={'white'}
                    bg={
                        startIndex >= testimonials.length - 1 ||
                        startIndex === testimonials.length - showAtaTime
                            ? buttonInActiveColor
                            : buttonActiveColor
                    }
                    _hover={{
                        bg:
                            startIndex >= testimonials.length - 1 ||
                            startIndex === testimonials.length - showAtaTime
                                ? buttonInActiveColor
                                : buttonActiveColor,
                    }}
                    onClick={() =>
                        startIndex >= testimonials.length - 1 ||
                        startIndex === testimonials.length - showAtaTime
                            ? null
                            : setStartIndex(startIndex + 1)
                    }
                >
                    <Icon as={FaChevronRight} />
                </Button>
            </HStack>
        </Container>
    )
}

export default Testimonials
