import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    Link,
    Skeleton,
    Stack,
    Text,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'
import { FC, RefObject } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { PortfolioData } from '../../portfolio'

interface PortfolioItemProps {
    name: string
    desc: string
    img: string
    webp: string
    link: string
}

const PortfolioItem: FC<PortfolioItemProps> = ({ name, desc, webp, link }) => {
    return (
        <Link href={link} isExternal _hover={{ textDecoration: 'none' }}>
            <Box
                width={{ base: '100%', sm: '300px', md: '280px' }}
                borderWidth="1px"
                borderRadius="2xl"
                overflow="hidden"
                px={4}
                py={6}
                bg="white"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                _hover={{
                    transform: 'translateY(-10px)',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                }}
                transition="transform 0.4s ease, box-shadow 0.4s ease"
                cursor="pointer"
            >
                <Box
                    borderRadius="lg"
                    overflow="hidden"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={4}
                    height="auto" // Allows the height to adjust based on the image's aspect ratio
                >
                    <Image
                        src={webp}
                        alt={name}
                        fallback={<Skeleton height="200px" />}
                        objectFit="contain" // Ensures the full image is displayed without cropping
                        width="100%"
                        height="100%"
                    />
                </Box>
                <Stack spacing={4} textAlign="center">
                    <Heading
                        as="h3"
                        size="md"
                        noOfLines={1}
                        fontWeight="bold"
                        color="gray.800"
                    >
                        {name}
                    </Heading>
                    <Text fontSize="sm" color="gray.600" noOfLines={3}>
                        {desc}
                    </Text>
                    <Center>
                        <Button
                            rightIcon={<BiLinkExternal />}
                            bgGradient="linear(to-r, pink.400, pink.500)"
                            color="white"
                            _hover={{
                                bgGradient: 'linear(to-r, pink.500, pink.600)',
                                transform: 'scale(1.05)',
                            }}
                            _active={{
                                bgGradient: 'linear(to-r, pink.600, pink.700)',
                            }}
                            shadow="md"
                            transition="transform 0.3s ease"
                        >
                            View Live
                        </Button>
                    </Center>
                </Stack>
            </Box>
        </Link>
    )
}

interface PortfolioProps {
    portfolioRef: RefObject<HTMLDivElement>
}

const Portfolio: FC<PortfolioProps> = ({ portfolioRef }) => {
    return (
        <Container
            ref={portfolioRef}
            maxW="7xl"
            py={16}
            as={Stack}
            spacing={12}
            align="center"
        >
            <Heading
                size="2xl"
                fontWeight="extrabold"
                textAlign="center"
                color="pink.500"
                mb={8}
            >
                Our Work
            </Heading>
            <Wrap spacing={{ base: 6, md: 10 }} justify="center">
                {PortfolioData.map((project, index) => (
                    <WrapItem key={index}>
                        <PortfolioItem
                            name={project.name}
                            desc={project.desc}
                            img={project.img}
                            webp={project.webp}
                            link={project.link}
                        />
                    </WrapItem>
                ))}
            </Wrap>
        </Container>
    )
}

export default Portfolio
