import { Box, Button, Container, Heading, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function Hero() {
    return (
        <Box
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Container maxW="7xl" textAlign="center">
                <Stack spacing={8} align="center">
                    <Heading
                        fontWeight={700}
                        fontSize={{ base: '3xl', md: '5xl' }}
                        lineHeight="110%"
                    >
                        Let's put your business <br />
                        <Text
                            as="span"
                            bgGradient="linear(to-r, pink.400, pink.600)"
                            bgClip="text"
                        >
                            Online
                        </Text>
                    </Heading>
                    <Text
                        fontSize={{ base: 'md', md: 'lg' }}
                        color="gray.600"
                        maxW="3xl"
                    >
                        Elementary Creative is your solution for a complete
                        digital takeover. We help you organize and optimize your
                        business for the modern world.
                    </Text>
                    <Stack direction="row" spacing={4}>
                        <Link to="/portfolio">
                            <Button
                                size="lg"
                                colorScheme="pink"
                                bg="pink.400"
                                rounded="full"
                                px={8}
                                _hover={{
                                    bg: 'pink.500',
                                    transform: 'translateY(-3px)',
                                    boxShadow: 'lg',
                                }}
                            >
                                Portfolio
                            </Button>
                        </Link>
                        <Link to="/contact">
                            <Button
                                size="lg"
                                variant="outline"
                                color="pink.400"
                                borderColor="pink.400"
                                rounded="full"
                                px={8}
                                _hover={{
                                    bg: 'pink.50',
                                    transform: 'translateY(-3px)',
                                    boxShadow: 'lg',
                                }}
                            >
                                Contact Us
                            </Button>
                        </Link>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}
