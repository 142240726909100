import {
    Box,
    Container,
    Flex,
    Icon,
    SimpleGrid,
    Stack,
    Text,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import {
    FcAssistant,
    FcCalendar,
    FcCheckmark,
    FcDataBackup,
    FcLock,
    FcMoneyTransfer,
} from 'react-icons/fc'

interface FeatureProps {
    title: string
    text: string
    icon: ReactElement
}

const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
        <Stack
            align={'center'}
            spacing={4}
            px={6}
            py={8}
            bg={'gray.50'}
            borderRadius={'lg'}
            shadow={'lg'}
            _hover={{
                boxShadow: 'xl',
                transform: 'scale(1.05)',
                transition: 'all 0.3s ease-in-out',
            }}
        >
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'blue.50'}
                mb={4}
            >
                {icon}
            </Flex>
            <Text fontSize={'xl'} fontWeight={600} color={'gray.800'}>
                {title}
            </Text>
            <Text
                fontSize={'md'}
                fontWeight="medium"
                color={'gray.600'}
                textAlign="center"
            >
                {text}
            </Text>
        </Stack>
    )
}

export default function Features() {
    return (
        <Container
            maxW={'7xl'}
            py={16}
            px={8}
            as={Stack}
            spacing={12}
            centerContent
        >
            <Box py={12}>
                <Text
                    fontSize="4xl"
                    fontWeight="extrabold"
                    color="gray.800"
                    mb={8}
                    textAlign="center"
                >
                    Our Features
                </Text>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing={10}
                    justifyItems="center"
                >
                    <Feature
                        icon={<Icon as={FcAssistant} w={12} h={12} />}
                        title={'Support'}
                        text={
                            'We are a small company based in Canada so you will get 1:1 support from us. No job too big or too small.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcCheckmark} w={12} h={12} />}
                        title={'Promise of Quality'}
                        text={
                            'We use AWS and Google Cloud on the backend to make sure your website is running on the best hardware.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcDataBackup} w={12} h={12} />}
                        title={'Backup'}
                        text={
                            'We do a monthly backup of your data, so in case things go wrong, we can restore the website to its original state.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcCalendar} w={12} h={12} />}
                        title={'Scheduling'}
                        text={
                            'We can help you setup online scheduling for your e-learning, car detailing or hair dressing business.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcMoneyTransfer} w={12} h={12} />}
                        title={'E-commerce'}
                        text={
                            'We can set up an ecommerce website for you that is cheaper than Shopify depending on the amount of traffic you are expecting.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcLock} w={12} h={12} />}
                        title={'Security'}
                        text={
                            "All our websites are protected with SSL to ensure the safety of your and your customer's data."
                        }
                    />
                </SimpleGrid>
            </Box>
        </Container>
    )
}
